<template>
  <span>
    <CoordinateManagementTemplate
      :images="images"
      :parentPlanPage="planPage"
      :parentScreenPage="screenPage"
      :parentCoordinateManagement="coordinateManagement"
      :parentClauseFlg="parentClauseFlg"
      :temporaryId="temporaryId"
      :onComplete="onComplete"
      :onCancel="onCancel"
      :completeButtonText="$t('button.register')"
      :dialogTitle="$t('title.agency.confirmAddHighLight')"
      :completeText="$t('description.confirmAddHighLight')"
      type="add"
    />
    <ErrorDialog ref="errorDialog" />
  </span>
</template>

<script>
import CoordinateManagementTemplate from '@/pages/agency/templates/CoordinateManagementTemplate';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import { getPdfImage, getPdfBlobToB64 } from '@/lib/commonUtil';
import {
  getApplicationFormMaster,
  getCoordinateManagementList,
  addCoordinateManagement,
} from '@/apis/agency/applicationForms';
import { mapActions } from 'vuex';
import { MaxCount } from '@/lib/const';

export default {
  name: 'AddCoordinateManagement',
  components: {
    CoordinateManagementTemplate,
    ErrorDialog,
  },
  data() {
    return {
      images: [],
      planPage: null,
      coordinateManagementList: [],
      temporaryId: null,
      coordinateManagement: {},
      screenPage: null,
      parentClauseFlg: false,
    };
  },
  created() {
    this.setLoading(true);
  },
  async mounted() {
    // 申込書取得
    const applicationFormPdf = await getApplicationFormMaster(
      this.$route.params['applicationFormId']
    );
    const b64 = await getPdfBlobToB64(applicationFormPdf);

    this.$set(this, 'images', await getPdfImage(b64));

    // ハイライト情報取得
    const applicationFormInfo = await getCoordinateManagementList(
      this.$route.params['applicationFormId']
    );

    // 上限まで登録されている場合は一覧画面にリダイレクトさせる
    if (applicationFormInfo.coordinateManagements.length >= MaxCount.HighLight)
      this.$router.push({
        path: '/application_form_list',
        query: {
          applicationFormId: this.$route.params['applicationFormId'],
        },
      });

    this.$set(
      this,
      'coordinateManagementList',
      applicationFormInfo.coordinateManagements
    );

    const plan = this.coordinateManagementList.find(
      coordinateManagement => coordinateManagement.planFlg
    );
    this.$set(this, 'planPage', plan ? plan.screenPage : null);

    const clause = this.coordinateManagementList.find(
      coordinateManagement => coordinateManagement.clauseFlg
    );
    this.$set(this, 'parentClauseFlg', clause ? true : false);

    this.screenPage = this.coordinateManagementList.length + 1;

    this.setLoading(false);
  },
  methods: {
    ...mapActions('ui', ['setLoading']),

    async onComplete(newCoordinateManagement) {
      const coordinateManagement = {
        applicationFormId: this.$route.params['applicationFormId'],
        xCoordinate: newCoordinateManagement.xCoordinate,
        yCoordinate: newCoordinateManagement.yCoordinate,
        width: newCoordinateManagement.width,
        height: newCoordinateManagement.height,
        screenPage: newCoordinateManagement.screenPage,
        pdfPage: newCoordinateManagement.pdfPage,
        description: newCoordinateManagement.description,
        singlePlanConfirmation: newCoordinateManagement.singlePlanConfirmation,
        multiplePlansConfirmation: newCoordinateManagement.multiplePlansConfirmation,
        planFlg: newCoordinateManagement.planFlg,
        importantFlg: newCoordinateManagement.importantFlg,
        skipFlg: newCoordinateManagement.skipFlg,
        clauseFlg: newCoordinateManagement.clauseFlg,
        modificationItems: newCoordinateManagement.clauseFlg
          ? null
          : newCoordinateManagement.modificationItems,
      };

      // 座標管理マスタ追加
      const result = await addCoordinateManagement(
        this.$route.params['applicationFormId'],
        coordinateManagement
      ).catch(err => {
        console.log(err);
      });
      if (result) {
        this.$router.push({
          path: '/application_form_list',
          query: {
            applicationFormId: this.$route.params['applicationFormId'],
          },
        });
      } else {
        this.$refs.errorDialog.open(
          this.$t('title.agency.highLightRegisterError'),
          this.$t('error.highLightRegisterBadRequest')
        );
      }
    },
    onCancel() {
      this.$router.push({
        path: '/application_form_list',
        query: {
          applicationFormId: this.$route.params['applicationFormId'],
        },
      });
    },
  },
};
</script>
