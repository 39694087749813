var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("CoordinateManagementTemplate", {
        attrs: {
          images: _vm.images,
          parentPlanPage: _vm.planPage,
          parentScreenPage: _vm.screenPage,
          parentCoordinateManagement: _vm.coordinateManagement,
          parentClauseFlg: _vm.parentClauseFlg,
          temporaryId: _vm.temporaryId,
          onComplete: _vm.onComplete,
          onCancel: _vm.onCancel,
          completeButtonText: _vm.$t("button.register"),
          dialogTitle: _vm.$t("title.agency.confirmAddHighLight"),
          completeText: _vm.$t("description.confirmAddHighLight"),
          type: "add"
        }
      }),
      _c("ErrorDialog", { ref: "errorDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }